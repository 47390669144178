<template>
  <div class="home view">
    <div class="inner">
      <div class="wrapper">
        <img class="profilePic" src="/img/profile.jpeg" alt="">
        <div class="content">
          <div class="fullName" id="homeNameElem">
            <div class="part" id="homeFirstName">
                <span>M</span>
                <span>ikkel</span>
            </div>
            <div class="part" id="homeLastName">
                <span>F</span>
                <span>rederiksen</span>
            </div>
          </div>
          <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum eos corrupti aspernatur ratione, odio, eius laboriosam exercitationem asperiores nostrum sint explicabo. Aut nulla dolores dolore suscipit reprehenderit. Ducimus inventore ex temporibus dolores, debitis impedit nulla?</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView'
}
</script>

<style scoped lang="scss">
.home {
  &.vuechange {
    &-down,
    &-up {
      &-enter-active {
        transition-duration: 1s;
      }
      &-enter-from {
        opacity: 0;
        transform: none !important;
      }
    }
  }
  .wrapper {
    display: flex;
    height: 100%;
    gap: 50px;
    .profilePic {
      width: 35%;
      height: 100%;
      flex-shrink: 0;
      object-fit: cover;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .home {
    .wrapper {
      flex-direction: column;
      .profilePic {
        height: 40%;
        width: 100%;
      }
    }
  }
}
</style>
