import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCss3Alt, faHtml5, faJs } from '@fortawesome/free-brands-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(
    faHtml5,
    faCss3Alt,
    faJs,
    faXmark
)

createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).mount('#app')
