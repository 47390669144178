<template>
<div id="primaryNavigation" class="primaryNavigation">
    <div class="inner">
        <div class="fullName fullNameLocation ending" ref="fullnameLocation">
            <div class="part" ref="firstNameLocation">
                <span>M</span>
                <span>ikkel</span>
            </div>
            <div class="part" ref="lastNameLocation">
                <span>F</span>
                <span>rederiksen</span>
            </div>
        </div>
        <button @click="() => { goTo('') }" :class="`fullName toAnimate ${fullnameclass}`">
            <div class="part" :style="`${firstNameLocationStyling}`">
                <span>M</span>
                <span>ikkel</span>
            </div>
            <div class="part" :style="`${lastNameLocationStyling}`">
                <span>F</span>
                <span>rederiksen</span>
            </div>
        </button>
        <transition>
            <router-link to="" @click="() => { goTo('') }" v-if="showExtention" class="extention">dev.dk</router-link>
        </transition>
        <div class="quickLinks">
            <button class="link" @click="() => {goTo('cases')}">My work</button>
            <button class="link" @click="() => {goTo('cv')}">CV</button>
            <button class="link" @click="() => {goTo('contact')}">Contact</button>
        </div>
    </div>
</div>
</template>

<script>
import emitter from 'tiny-emitter/instance';
export default {
    mounted() {
        emitter.on('awayFromHome', this.startAnimation);
        emitter.on('returnToHome', this.runAnimationBack);
        if (window.location.pathname != '/') {
            this.currentMode = 'done';
            this.setEndLocations();
        }

    },
    watch: {
        $route: function (newRoute) {
            if (newRoute.path == '/') {
                this.showExtention = false;
            } else {
                this.showExtention = true;
            }
        }
    },
    computed: {
        firstNameLocationStyling() {
            const mode = this.currentMode;
            if (mode == 'started' || mode == 'reset') {
                return `top: ${this.firstNameStartLocation.top}px; left: ${this.firstNameStartLocation.left}px; width: ${this.firstNameStartLocation.width}px; height: ${this.firstNameStartLocation.height};`;
            }
            return `top: ${this.firstNameEndLocation.top}px; left: ${this.firstNameEndLocation.left}px; width: ${this.firstNameEndLocation.width}px; height: ${this.firstNameEndLocation.height};`;
        },
        lastNameLocationStyling() {
            const mode = this.currentMode;
            if (mode == 'started' || mode == 'reset') {
                return `top: ${this.lastNameStartLocation.top}px; left: ${this.lastNameStartLocation.left}px; width: ${this.lastNameStartLocation.width}px; height: ${this.lastNameStartLocation.height};`;
            }
            return `top: ${this.lastNameEndLocation.top}px; left: ${this.lastNameEndLocation.left}px; width: ${this.lastNameEndLocation.width}px; height: ${this.lastNameEndLocation.height};`;
        },
        fullnameclass() {
            if (['middle', 'end'].includes(this.currentMode)) {
                return 'ending';
            }
            if (this.currentMode == 'done') {
                return 'ending static';
            }
            if (this.currentMode == 'reset') {
                return 'transition-only';
            }
            return 'hidden';
        }
    },
    data() {
        return {
            firstNameEndLocation: {
                top: 0,
                left: 0
            },
            firstNameStartLocation: {
                top: 0,
                left: 0
            },
            lastNameEndLocation: {
                top: 0,
                left: 0
            },
            lastNameStartLocation: {
                top: 0,
                left: 0
            },
            awayFromHome: false,
            currentMode: 'startPoint',
            showExtention: false
        }
    },
    methods: {
        startAnimation(cb) {
            const homeFirstName = document.getElementById('homeFirstName');
            const homeLastName = document.getElementById('homeLastName');
            const homeFirstNameLocation = homeFirstName.getBoundingClientRect();
            const homeLastNameLocation = homeLastName.getBoundingClientRect();
            this.firstNameStartLocation.left = homeFirstNameLocation.left;
            this.firstNameStartLocation.top = homeFirstNameLocation.top;
            this.lastNameStartLocation.left = homeLastNameLocation.left;
            this.lastNameStartLocation.top = homeLastNameLocation.top;
            const endLocationFirstNameElem = this.$refs.firstNameLocation;
            const endLocationLastNameElem = this.$refs.lastNameLocation;
            const endLocationFirstName = endLocationFirstNameElem.getBoundingClientRect();
            const endLocationLastName = endLocationLastNameElem.getBoundingClientRect();
            this.firstNameEndLocation.left = endLocationFirstName.left;
            this.firstNameEndLocation.top = endLocationFirstName.top;
            this.lastNameEndLocation.left = endLocationLastName.left;
            this.lastNameEndLocation.top = endLocationLastName.top;
            this.currentMode = 'started';
            setTimeout(() => {
                homeFirstName.style.visibility = 'hidden';
                homeLastName.style.visibility = 'hidden';
            }, 0);
            setTimeout(() => {
                this.currentMode = 'middle';
                cb();
            }, 10);
            setTimeout(() => {
                this.currentMode = 'done';
            }, 650);
        },
        runAnimationBack() {
            this.$router.push('/');
            setTimeout(() => {
                const endLocationFirstNameElem = this.$refs.firstNameLocation;
                const endLocationLastNameElem = this.$refs.lastNameLocation;
                const endLocationFirstName = endLocationFirstNameElem.getBoundingClientRect();
                const endLocationLastName = endLocationLastNameElem.getBoundingClientRect();
                console.log(endLocationFirstNameElem)
                this.firstNameEndLocation.left = endLocationFirstName.left;
                this.firstNameEndLocation.top = endLocationFirstName.top;
                this.lastNameEndLocation.left = endLocationLastName.left;
                this.lastNameEndLocation.top = endLocationLastName.top;
                setTimeout(() => {
                    const homeFirstName = document.getElementById('homeFirstName');
                    const homeLastName = document.getElementById('homeLastName');
                    const homeFirstNameLocation = homeFirstName.getBoundingClientRect();
                    const homeLastNameLocation = homeLastName.getBoundingClientRect();
                    this.firstNameStartLocation.left = homeFirstNameLocation.left;
                    this.firstNameStartLocation.top = homeFirstNameLocation.top;
                    this.lastNameStartLocation.left = homeLastNameLocation.left;
                    this.lastNameStartLocation.top = homeLastNameLocation.top;
                    setTimeout(() => {
                        this.currentMode = 'reset';
                    }, 0);
                    setTimeout(() => {
                        homeFirstName.style.visibility = 'visible';
                        homeLastName.style.visibility = 'visible';
                        this.currentMode = '';
                    }, 1000)
                }, 650);
            }, 10)
        },
        goTo(page) {
            emitter.emit('pageTransition', page);
        },
        setEndLocations() {
            const endLocationFirstNameElem = this.$refs.firstNameLocation;
            const endLocationLastNameElem = this.$refs.lastNameLocation;
            const endLocationFirstName = endLocationFirstNameElem.getBoundingClientRect();
            const endLocationLastName = endLocationLastNameElem.getBoundingClientRect();
            this.firstNameEndLocation = {
                left: endLocationFirstName.left,
                top: endLocationFirstName.top
            };
            this.lastNameEndLocation = {
                left: endLocationLastName.left,
                top: endLocationLastName.top
            };
        }
    }
}
</script>

<style lang="scss" scoped>
$transitionDuration: .5s;
$transitionDelay: .1s;
#primaryNavigation {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    display: flex;
    align-items: center;
    z-index: 999;
    .inner {
        border-radius: 10px;
        height: 70%;
        width: 80%;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 0 20px;
        .quickLinks {
            margin-left: auto;
            display: flex;
            gap: 20px;
            .link {
                background-color: transparent;
                transition-property: color;
                transition-timing-function: ease;
                transition-duration: .15s;
                cursor: pointer;
                &:hover {
                    color: white;
                }

            }
        }
        .extention {
            font-size: 20px;
            max-width: 60px;
            overflow: hidden;
            &.v-enter-active,
            &.v-leave-active {
                transition-property: opacity, max-width;
                transition-timing-function: ease;
                transition-duration: .3s;
            }
            &.v-enter-active {
                transition-delay: calc($transitionDuration + .2s);
            }
            &.v-leave-to,
            &.v-enter-from {
                // opacity: 0;
                max-width: 0;
            }
        }
    }

    .fullName {
        cursor: default;
        background: transparent;
        &.toAnimate {
            &.hidden {
                visibility: hidden;
            }
            .part {
                position: absolute;
                span:last-child {
                    display: inline-block;
                    max-width: 0;
                    transition-property: max-width;
                    transition-timing-function: ease;
                    transition-duration: calc($transitionDuration - .2s);
                }
            }
        }
        &:not(.ending):not(.transition-only) {
            .part {
                span:last-child {
                    max-width: 320px;
                    overflow: hidden;
                }
                &:first-child {
                    span:last-child {
                        max-width: 150px;

                    }
                }
            }
        }
        &.ending {
            transition-property: font-size;
            transition-timing-function: ease;
            transition-duration: $transitionDuration;
            .part {
                transition-property: left, top, position;
                transition-timing-function: ease;
                transition-duration: $transitionDuration;
                span:last-child {
                    max-width: 0;
                    overflow: hidden;
                }
                display: flex;
                align-items: flex-end;
                &:nth-child(2) {
                    transition-delay: $transitionDelay;
                    span:last-child {
                        transition-delay: $transitionDelay;
                    }
                }
            }
            font-size: 25px;
            display: flex;
        }
        &.transition-only {
            transition-property: font-size;
            transition-timing-function: ease;
            transition-duration: $transitionDuration;
            .part {
                transition-property: left, top, position;
                transition-timing-function: ease;
                transition-duration: $transitionDuration;
                span:last-child {
                    transition-property: max-width;
                    transition-timing-function: ease;
                    transition-duration: calc($transitionDuration - .1s);
                    transition-delay: .1s;
                    max-width: 350px;
                    overflow: hidden;
                }
                display: flex;
                align-items: flex-end;
                &:nth-child(2) {
                    transition-delay: 0;
                }
                &:nth-child(1) {
                    transition-delay: $transitionDelay;
                    span:last-child {
                        max-width: 150px;
                    }
                }
            }
        }
        &.static {
            cursor: pointer;
            .part {
                position: static;
            }
        }

        &Location {
            position: relative;
            width: 0;
            visibility: hidden;
            opacity: 0;
        }
    }
}

</style>