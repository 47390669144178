<template>
  <primary-navigation/>
  <div class="view-container">
    <router-view v-slot="{ Component }">
    <transition :name="`vuechange-${direction}`" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
</template>

<script>
import PrimaryNavigation from './components/PrimaryNavigation.vue';
import emitter from 'tiny-emitter/instance';
export default {
  components: {
    PrimaryNavigation
  },
  mounted() {
    // document.addEventListener('wheel', this.emitMove);
    document.addEventListener('keyup', this.clickEvent);
    emitter.on('pageTransition', this.goTo);
    const routeName = window.location.pathname.split('/')[1];
    switch (routeName) {
      case '':
        this.pageIndex = 0;
        break;
      case 'tech-stack':
        this.pageIndex = 1;
        break;
      case 'cases':
        this.pageIndex = 2;
        break;
    }
  },
  data() {
    return {
      pageIndex: 0,
      pages: ['', 'cases', 'cv', 'contact'],
      cooldown: null,
      direction: 'up'
    }
  },
  methods: {
    emitMove(dir, keepIndex) {
      if (this.cooldown != null) return;
      if (dir == 'down') {
        this.direction = 'down';
        if (this.pageIndex == 0 || (keepIndex && this.pageIndex != 0 && this.$route.name == 'home')) {
          emitter.emit('awayFromHome', () => {
            this.$router.push(`/${this.pages[this.pageIndex]}`);
          });
          if (!keepIndex) {
            this.pageIndex++;
          }
        } else {
          if (!keepIndex) {
            this.pageIndex++;
          }
          this.$router.push(`/${this.pages[this.pageIndex]}`);
        }
      } else {
        this.direction = 'up';
        if ((this.pageIndex == 1 && !keepIndex) || (this.pageIndex == 0 && keepIndex)) {
          emitter.emit('returnToHome');
          if (!keepIndex) {
            this.pageIndex--;
          }
        } else {
          if (!keepIndex) {
            this.pageIndex--;
          }
          this.$router.push(`/${this.pages[this.pageIndex]}`)
        }
      }
      this.setCooldown();
    },
    clickEvent(e) {
      if (e.code == 'ArrowDown') {
        this.emitMove('down');
      } else if (e.code == 'ArrowUp') {
        this.emitMove('up') 
      }
    },
    clearListener() {
      document.removeEventListener('wheel', this.emitMove);
    },
    setCooldown() {
      this.cooldown = setTimeout(() => {
        clearTimeout(this.cooldown);
        this.cooldown = null;
      }, 300)
    },
    goTo(page) {
      const pageIndex = this.pages.findIndex(o => o == page);
      if (pageIndex < 0) return;
      if (pageIndex > this.pageIndex) {
        this.direction = 'down';
      } else {
        this.direction = 'up';
      }
      this.pageIndex = pageIndex;
      this.emitMove(this.direction, true);
    }
  }
}
</script>
<style lang="scss">
@import "@/styles.scss";
</style>
